//ポイントボックス

.c-pointBox {
  border: 2px solid $gray;
  padding: 50px 96px;
  background-color: $white;
  @include mq-max(content) {
    padding: 50px 40px;
  }
  @include mq-max(xs) {
    padding: 40px 20px;
  }
  &__list {
    counter-reset: point;
  }
  &__item {
    //display: flex;
    //align-items: center;
    border-bottom: 1px solid $gray;
    padding: 25px 0;
    //@include mq-max(sm) {
    //  flex-direction: column;
    //  align-items: flex-start;
    //}
    &:first-child {
      padding-top: 0;
      padding-bottom: 22px;
    }
    &:last-child {
      border-bottom: none;
      padding-top: 22px;
      padding-bottom: 0;
    }
  }
  &__desc {
    //width: calc(100% - 345px);
    //margin-left: 45px;
    //@include mq-max(sm) {
    //  width: 100%;
    //  margin-left: 0;
    //}
    p {
      line-height: 1.75;
    }
  }


  //パーツ調整
  .c-ttl-3 {
    //width: 345px;
    width: 100%;
    margin-bottom: 10px;
    padding-left: 50px;
    position: relative;
    font-weight: bold;
    //@include mq-max(sm) {
    //  width: 100%;
    //  margin-bottom: 20px;
    //}
    //@include mq-max(xs) {
    //  padding-left: 50px;
    //}
    &:before {
      content: counter(point,decimal-leading-zero);
      counter-increment: point;
      font-size: 36px;
      font-weight: 600;
      color: $keyColor;
      line-height: 1;
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
}