.l-navigation {
  display: flex;

  //@include mq-max(md) {
    display: none;

    html.open &.active>ul {
      overflow-y: scroll;
      height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
      padding-bottom: 120px;
    //}
  }

  &.active {
    transform: translateY(0%);
  }

  //menu.js制御用クラス
  &__jsInner {
    display: flex;

    //@include mq-max(md) {
      flex-direction: column;
      height: calc(100vh - #{$headerHeight-sp});
      overflow-y: scroll;
    //}
  }

  &__wrap {
    margin-right: 30px;

    //@include mq-max(md) {
      margin-right: 0;
      display: flex;
      flex-direction: column-reverse;
      padding-top: 20px;
      background-color: $white;
    //}
  }

  //お問い合わせ
  &__contact {
    &>a {
      background-color: $keyColor;
      color: $white;
      font-size: 10px;
      font-weight: 600;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 13px;
      transition: all $transition;

      @include mq-max(md) {
        padding: 8px 10px;
      }

      &:before {
        @include fa('f0e0');
        font-weight: 400;
        font-size: 29px;
        line-height: 1;
      }

      &:hover {
        color: $white;
        opacity: .7;
      }
    }
  }

  //上Navi
  &__top {
    display: flex;
    justify-content: flex-end;
    margin-right: 6px;
    //@include mq-max(md) {
      background-color: $white;
      justify-content: center;
      width: 100%;
      padding: 8.5px 0;
    //}
  }

  &__topItem {
    margin: 0 12.5px;

    //@include mq-max(md) {
      margin: 0;
      padding: 0 18.5px;
      border-right: 1px solid $bdrColor;
      width: 100%;

      &:last-child {
        border-right: none;
      }
    //}

    &>a {
      color: $black;
      font-size: 15px;
      display: flex;
      align-items: center;
      padding: 14px 0 5.9px;
      padding-bottom: 0;
      transition: opacity .4s;
      opacity: 1;
      font-weight: 600;
      //@include mq-max(md) {
        padding: 11.5px 0;
        justify-content: center;
      //}

      &:before {
        content: '';
        margin-right: 8px;
        color: $keyColor;
      }
      &:hover {
        opacity: 0.7;

      }
    }


    &--recruit {
      &>a {
        &:before {
          @include fa('f406');
        }
      }
    }

    &--news {
      &>a {
        &:before {
          @include fa('f022');
        }
      }
    }

    &--eng {
      &>a {
        &:before {
          @include fa('f0ac');
        }
      }
    }
  }

  //下Navi
  &__inner {
    padding: 0;
    display: flex;
    flex-direction: column;

    //@include mq-max(md) {
      //display: block;
      height: auto !important;
    //}
  }

  &__item {

    position: relative;

    &:last-child {
      margin-right: 0;
    }

    //@include mq-max(md) {
      margin-right: 0;
      border-top: 1px solid $gray;

      &:first-child {
        border-top: none;
      }

      &:last-child {
        border-bottom: 1px solid $gray;
      }
    //}

    a {
      font-size: 15px;

      //@include mq-max(md) {
        background-color: $white;
        position: relative;

        &:after {
          @include fa('f054');
          position: absolute;
          top: 50%;
          right: 28px !important;
          transform: translateY(-50%);
        }
      //}
    }

    &>a {
      text-decoration: none;
      color: $textColor;
      font-weight: 600;
      display: inline-block;
      padding: 20px 19px;
      position: relative;
      padding-top: 15px;

      //@include mq-max(bp1200) {
      //  padding: 33px 15px;
      //}

      //@include mq-max(md) {
        display: block;
        padding: 11px 45px 11px 28px;
        font-size: 16px;

        &:before {
          display: none !important;
        }
      //}

      &:hover {
        color: $keyColor;

        &:before {
          width: 95%;
        }
      }

      &:before {
        content: '';
        display: block;
        width: 0%;
        height: 2px;
        background-color: $keyColor;
        position: absolute;
        bottom: 0;
        left: (100% - 95%) / 2;
        transition: all $transition;
      }

      &.current {
        //@include mq-max(md) {
          color: $keyColor;
        //}

        &:before {
          width: 95%;
        }
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a {
        position: relative;

        &:after {
          @include fa('f107');
          position: absolute;
          top: 50%;
          right: 4px;
          transform: translateY(-50%);
          line-height: 1;
          font-size: 16px;

          //@include mq-max(md) {
            @include fa('f067');
            right: 16px;
          }
        //}

        &.has-dropdown {
          &:after {
            //@include mq-max(md) {
              content: '\f068';
            }
          //}
        }

        &[href^="javascript:void(0)"] {
          pointer-events: auto;
        }
      }


      //クリックで展開するようにするためにコメントアウト（is-openedの下に移動）
      // &:hover {
      //   .l-dropDown {
      //     top: $headerHeight; // headerの高さ
      //     opacity: 1;
      //     visibility: visible;
      //     @include mq-max(md) {
      //       top: auto;
      //     }
      //     a {
      //       transition: color $transition,
      //                   height $transition;
      //       &:hover {
      //         color: $keyColor;
      //       }
      //     }
      //   }
      // }
      .l-dropDown {
        position: absolute;
        top: $headerHeight - 30px; // headerの高さ - 30px
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        padding: 0 24px;
        background-color: $white;
        overflow: hidden;

        //@include mq-max(md) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          padding: 0;
          border-radius: 0;
        //}

        li {
          border-bottom: 1px solid $gray;

          //@include mq-max(md) {
            border-bottom: none;
          //}

          &:last-child {
            border: none;
          }

          a {
            background-color: $white;
            padding: 18px 0;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;

            //@include mq-max(md) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                opacity 0.4s,
                padding $transition,
                line-height $transition;
            //}

            &:after {
              //@include mq-max(md) {
                font-size: 13px;
              //}
            }
          }
        }
      }

      &.is-opened {
        .l-dropDown {
          top: calc(#{$headerHeight} + -0px); // headerの高さ
          top: 70px;
          opacity: 1;
          visibility: visible;

          //@include mq-max(md) {
            top: auto;
          //}

          a {
            transition: color $transition,
              height $transition;

            &:hover {
              color: $keyColor;
            }
          }
        }

        &>a {
          //@include mq-max(md) {
            &:after {
              content: '\f068';
            }
          //}
        }

        >.l-dropDown {
          //@include mq-max(md) {
            opacity: 1;
            display: block;

            >li {
              border-bottom: 1px solid $gray;

              &:first-child {
                border-top: 1px solid $gray;
              }

              &:last-child {
                border-bottom: none;
              }
            }

            >li>a {
              opacity: 1;
              line-height: 1.5;
              height: 46px;
              padding: 11px 45px 11px 50px;
              background-color: $lightGray;
            }
          //}
        }
      }
    }
  }
    //商品紹介のナビ調整
    .p-mr10 {
      margin-right: 10px !important;
      @media screen and (max-width: 1024px) {
        margin-right: 0px !important;
      }
    }

}


//強調メニュー（右端）
.l-nav {
  margin-left: 13px;

  //@include mq-max(md) {
    margin-left: 0;
  //}

  &__btns {
    display: flex;
    height: 100%;
  }

  &__item {
    //@include mq-max(md) {
      //      width: 50%;
      width: 100%;
    //}

    &--1 {
      &>a {
        background-color: $keyColor;

        &>span {
          &:before {
            width: 23px;
            height: 30px;
            background: url('/inc/image/common/ico_mail-white.png')no-repeat center / contain;
            margin-bottom: 3px;
          }
        }
      }
    }

    //    twigもコメントアウト中。使用する場合はスタイル調整
    //    &--2 {
    //      &>a {
    //        background-color: $keyColor-dark;
    //        &>span {
    //          &:before {
    //            width: 29px;
    //            height: 29px;
    //            background: url('/inc/image/common/ico_doc-white.png')no-repeat center / contain;
    //            margin-bottom: 3px;
    //          }
    //        }
    //      }
    //    }

    &>a {
      color: $white;
      position: relative;
      display: inline-block;
      width: 88px;
      height: 100%;
      transition: all $transition;

      //@include mq-max(md) {
        width: 100%;
        height: 60px;
      //}

      &:hover {
        color: $white;
        opacity: .7;
      }

      &>span {
        color: inherit;
        display: block;
        font-size: 10px;
        font-weight: 600;
        text-align: center;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &:before {
          content: '';
          display: block;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}




//ドロップダウン展開時のフィルター
.l-filter {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  transition: opacity $transition;
  pointer-events: none;

  //@include mq-max(md) {
    display: none !important;
  //}

  &.is-active {
    opacity: .4;
    pointer-events: auto;
  }
}